import React, { forwardRef, useState } from 'react';
import axios from 'axios';
import { HiX } from 'react-icons/hi';
import * as Dialog from '@radix-ui/react-dialog';
import { baseUrl, headers } from '../../app/lookups';
import AlertMessage from '../../components/AlertMessage';

const RejectUsersModal = forwardRef(({ setShowModal, usersToReject }, ref) => {
  const [response, setResponse] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState({});

  const handleReasonChange = (email, reason) => {
    setRejectionReasons((prev) => ({ ...prev, [email]: reason }));
  };

  const handleSubmit = async (users) => {
    const rejectionData = users.map((user) => ({
      email: user.email,
      reason_denied: rejectionReasons[user.email] || '',
    }));

    const responseData = await axios.post(
      `${baseUrl}/user-service/reject`,
      rejectionData,
      { headers },
    );
    setResponse(responseData.data);
    setDisplayAlert(true);
  };

  return (
    <div ref={ref}>
      <Dialog.Content className="w-[40vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="relative w-auto my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
              <div className="text-3xl font-bold text-platform-primary-grey-800">
                Reject Users
              </div>
              <button
                type="button"
                aria-label="close-modal-button"
                className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800  border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
                onClick={() => {
                  setShowModal(false);
                }}>
                <HiX />
              </button>
            </div>
            <div className="relative p-6 flex-auto max-h-[60vh] overflow-y-auto">
              <p className="mb-4 text-platform-primary-grey-700">
                The following users will be rejected:
              </p>
              {usersToReject.map((u) => (
                <div key={u.id} className="flex items-center mb-4 space-x-4">
                  <span className="w-1/3 text-sm font-medium text-platform-primary-grey-700 truncate">
                    {u.email}
                  </span>
                  <input
                    type="text"
                    id={`reason-${u.id}`}
                    className="flex-grow p-2 text-sm border rounded-md focus:ring-2 focus:ring-platform-cta-info-600 focus:border-transparent"
                    placeholder="Reason for rejection"
                    value={rejectionReasons[u.email] || ''}
                    onChange={(e) =>
                      handleReasonChange(u.email, e.target.value)
                    }
                  />
                </div>
              ))}
              <p className="mt-4">
                <strong>
                  Are you sure you want to reject {usersToReject.length}{' '}
                  {usersToReject.length === 1 ? 'user' : 'users'}? This
                  procedure cannot be undone!
                </strong>
              </p>
            </div>
            <div className="p-2 border-t border-solid border-platform-primary-grey-200 rounded-b">
              {displayAlert &&
              response?.contact?.status &&
              response?.contact?.status === 200 ? (
                <AlertMessage
                  success
                  message="Users rejected"
                  closeAlert={() => setDisplayAlert(false)}
                />
              ) : displayAlert ? (
                <AlertMessage
                  success={false}
                  message={`Users were not successfully rejected. ${
                    response && `Error: ${JSON.stringify(response)}`
                  }`}
                  closeAlert={() => setDisplayAlert(false)}
                />
              ) : null}
              <div className="flex justify-end my-2">
                <button
                  className="mx-1 bg-platform-cta-info-800 text-black text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-info-800 hover:border-platform-cta-info-800 border-1"
                  type="button"
                  aria-label="cancel-button"
                  onClick={() => {
                    setShowModal(false);
                  }}>
                  Cancel
                </button>
                <button
                  className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                  type="button"
                  aria-label="back-button"
                  onClick={
                    response?.contact?.status &&
                    response?.contact?.status === 200
                      ? () => setShowModal(false)
                      : () => handleSubmit(usersToReject)
                  }>
                  {response?.contact?.status &&
                  response?.contact?.status === 200
                    ? 'Back'
                    : 'Reject Users'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </div>
  );
});

export default RejectUsersModal;
