import React, { forwardRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { HiPencil, HiX } from 'react-icons/hi';
import * as XLSX from 'xlsx';
import { utils } from 'xlsx';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dateFormat from 'dateformat';
import ExcelTemplate from '../../assets/bulk-user-creation-template.xlsx';
import SenseAttributesPopover from './SenseAttributesPopover';
import { useUsersAPI } from '../../app/userContext';

const BulkCreateUsersModal = forwardRef(({ setShowModal }, ref) => {
  const columnHelper = createColumnHelper();
  const [fileInputKey, setFileInputKey] = useState(0);
  const [rows, setRows] = useState([]);

  const { roles } = useUsersAPI();

  // Allowed extensions for input file
  const allowedExtensions = ['xls', 'xlsx'];

  const [displayFileError, setDisplayFileError] = useState(false);

  // It will store the file uploaded by the user
  const [file, setFile] = useState('');

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setDisplayFileError(false);

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const filenameSplit = inputFile?.name.toLowerCase().split('.');
      const fileExtension = filenameSplit[filenameSplit.length - 1];
      if (!allowedExtensions.includes(fileExtension)) {
        setDisplayFileError(true);
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };

  const handleFileUpload = async () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result);
      const data = utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: 1 },
      );
      setRows(
        data
          .slice(1)
          .map((row) =>
            Object.assign(
              ...data[0].map((key, index) => ({ [key]: row[index] })),
            ),
          ),
      );
    };
    reader.readAsArrayBuffer(file);
    setFile('');
    setFileInputKey(fileInputKey + 1); // Forces file input component to reload and so clears it
  };

  const buildDate = (date) => (
    <div className="flex py-4 min-w-min">{dateFormat(date, 'dd-mm-yy')}</div>
  );

  const buildEditButton = () => (
    <div className="flex py-4 justify-start">
      <div className="">
        <button
          aria-label="edit-build-button"
          type="button"
          className="mx-0.5 p-2 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          <HiPencil />
        </button>
      </div>
    </div>
  );

  const buildName = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {`${info.row.original.firstname} ${info.row.original.surname} (${info.row.original.nickname})`}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.email}
      </div>
    </div>
  );

  const buildPosition = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {info.row.original.job_title}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.primary_trust}
      </div>
    </div>
  );

  const buildSenseAttributes = (senseAttributes) => (
    <SenseAttributesPopover senseAttributes={senseAttributes} />
  );

  const columns = [
    columnHelper.accessor('edit', {
      header: 'Edit User',
      cell: (cell) => buildEditButton(cell.row.original),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      cell: (info) => buildName(info),
      header: 'Name',
    }),
    columnHelper.accessor('position', {
      id: 'position',
      cell: (info) => buildPosition(info),
      header: 'Position',
    }),
    columnHelper.accessor((row) => row.primary_group, {
      id: 'primary_group',
      cell: (info) => info.getValue(),
      header: 'AdviseInc Group',
    }),
    columnHelper.accessor((row) => row.start_date, {
      id: 'start_date',
      cell: (info) => buildDate(info.getValue()),
      header: 'Start Date',
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: 'end_date',
      cell: (info) => buildDate(info.getValue()),
      header: 'End Date',
    }),
    columnHelper.accessor('lockedToGroup', {
      id: 'lockedToGroup',
      cell: (cell) =>
        cell.row.original['Locked to group?'] === 'Y' ? 'true' : 'false',
      header: 'Locked To Group?',
    }),
    columnHelper.accessor('defaultView', {
      id: 'defaultView',
      cell: (cell) => cell.row.original['Group or Org default?'],
      header: 'Default View',
    }),
    columnHelper.accessor((row) => row.sense_attributes, {
      id: 'senseAttributes',
      cell: (cell) => buildSenseAttributes(cell.row.original.sense_attributes),
      header: 'Sense Attributes',
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.roles, {
      id: 'roles',
      cell: (cell) =>
        roles
          .map((role) => (cell.row.original[role.name] ? role.name : null))
          .filter((item) => item !== null)
          .join(', '),
      header: 'Roles',
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div ref={ref}>
      <Dialog.Content className="w-[80vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="flex justify-center items-center">
          <div className="h-[90vh] rounded-lg shadow-lg flex flex-col w-full bg-white border border-platform-primary-grey-200">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Create Multiple Users</h3>
              <button
                type="button"
                aria-label="close-modal-button"
                className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800"
                onClick={() => {
                  setShowModal(false);
                }}>
                <HiX />
              </button>
            </div>
            <div className="relative p-6 flex-auto overflow-y-scroll">
              <div className=" flex flex-col">
                <div className="font-extrabold text-l text-left py-1">
                  To upload multiple users use this&nbsp;
                  <button
                    type="button"
                    aria-label="excel-template-buttton"
                    className="text-blue-600 dark:text-blue-500 hover:underline">
                    <a
                      href={ExcelTemplate}
                      download="adviseinc-user-template.xlsx">
                      template
                    </a>
                  </button>
                  :
                </div>
                <div className="flex">
                  <div className="flex flex-col w-full px-3 mb-6">
                    <input
                      className={`block w-full cursor-pointer leading-tight file:rounded-md file:border ${
                        file === '' && !displayFileError
                          ? 'border-platform-primary-orange-800 file:border-platform-primary-orange-800'
                          : 'border-platform-primary-grey-200'
                      } file:py-1 file:bg-white file:font-medium file:text-platform-primary-grey-800 border rounded-md hover:bg-platform-ainc-grey-400 focus:outline-platform-primary-orange-800`}
                      id="file_input"
                      type="file"
                      key={fileInputKey}
                      onChange={handleFileChange}
                    />
                    {displayFileError ? (
                      <div
                        className="flex p-4 mt-2 mb-4 text-sm rounded-md bg-platform-cta-error-400"
                        role="alert">
                        Please input a valid file of type .xls or .xlsx
                      </div>
                    ) : null}
                  </div>
                  <div className="py-1 px-3">
                    <button
                      type="button"
                      aria-label="upload-multiple-users-button"
                      disabled={file === '' || displayFileError}
                      onClick={handleFileUpload}
                      className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                      Upload multiple users
                    </button>
                  </div>
                </div>
                {!displayFileError && rows.length > 0 ? (
                  <div className="">
                    <table className="w-full text-sm text-left text-platform-primary-grey-800">
                      <thead className="text-xs text-platform-primary-grey-800 uppercase bg-platform-secondary-grey-200">
                        {table.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th
                                key={header.id}
                                colSpan={header.colSpan}
                                className="p-2">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext(),
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows.map((row) => (
                          <tr
                            className="bg-white hover:bg-platform-ainc-grey-400 border border-platform-primary-grey-200"
                            key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id} className="py-1 px-1.5">
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-platform-primary-grey-200 rounded-b">
              <button
                className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                type="button"
                aria-label="clear-form-button"
                onClick={() => setRows([])}>
                {' '}
                Clear Form
              </button>
              <button
                className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                type="button"
                aria-label="create-users-button"
                // disabled={rows.length === 0}
                disabled>
                {' '}
                Create Users
              </button>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </div>
  );
});

export default BulkCreateUsersModal;
