import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dateFormat from 'dateformat';
import { useUsersAPI } from '../../app/userContext';

export default function PreRegistrationTable() {
  const columnHelper = createColumnHelper();

  const { preRegUsers } = useUsersAPI();

  const buildName = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {`${info.row.original.first_name} ${info.row.original.surname}`}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.email}
      </div>
    </div>
  );

  const buildStatus = (info) => {
    const status = info.row.original.approval_granted
      ? 'approved'
      : info.row.original.approval_requested
      ? 'awaiting approval'
      : info.row.original.date_approval_denied !== null
      ? 'rejected'
      : 'pending';
    return (
      <span
        className={`px-3 py-1 justify-center uppercase leading-wide font-bold text-xs rounded-full shadow-sm
          ${
            status.startsWith('approved')
              ? 'bg-platform-cta-success-200 text-platform-cta-success-600'
              : status.startsWith('awaiting approval')
              ? 'bg-platform-secondary-orange-200 text-platform-secondary-orange-600'
              : status.startsWith('rejected')
              ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
              : status.startsWith('pending')
              ? 'bg-platform-primary-blue-200 text-platform-primary-blue-800'
              : null
          }`}>
        {status}
      </span>
    );
  };

  const buildPosition = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {info.row.original.job_title}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.crm_name}
      </div>
    </div>
  );

  const buildSubscriptions = (info) => (
    <div className="pl-3">
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.applications_subscription}
      </div>
    </div>
  );

  const buildRequester = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {info.row.original.requested_by}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {dateFormat(info.row.original.date_requested, 'dd-mm-yy')}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.requested_by_email}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.requested_by_job_title}
      </div>
    </div>
  );

  const buildApprover = (info) => (
    <div className="pl-3">
      {info.row.original.approver !== null ? (
        <>
          <div className="text-base font-semibold">
            {info.row.original.approver}
          </div>
          <div className="font-normal text-platform-primary-grey-400">
            {dateFormat(info.row.original.date_approval_requested, 'dd-mm-yy')}
          </div>
        </>
      ) : (
        <div className="justify-center text-base font-semibold">-</div>
      )}
    </div>
  );

  const buildRejection = (info) => (
    <div className="pl-3">
      {info.row.original.date_approval_denied !== null ? (
        <>
          <div className="text-base font-semibold">
            {dateFormat(info.row.original.date_approval_denied, 'dd-mm-yy')}
          </div>
          <div className="font-normal text-platform-primary-grey-400">
            {info.row.original.reason_denied}
          </div>
        </>
      ) : (
        <div className="justify-center text-base font-semibold">-</div>
      )}
    </div>
  );

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (info) => buildName(info),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => buildStatus(info),
    }),
    columnHelper.accessor('position', {
      header: 'Position',
      cell: (info) => buildPosition(info),
    }),
    columnHelper.accessor('position', {
      header: 'Subscriptions',
      cell: (info) => buildSubscriptions(info),
    }),
    columnHelper.accessor('requestedBy', {
      header: 'Requested By',
      cell: (info) => buildRequester(info),
    }),
    columnHelper.accessor('approvedBy', {
      header: 'Approved By',
      cell: (info) => buildApprover(info),
    }),
    columnHelper.accessor('rejection', {
      header: 'Rejection Details',
      cell: (info) => buildRejection(info),
    }),
  ];

  const table = useReactTable({
    data: preRegUsers.sort((a, b) => a.id - b.id),
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="">
      <table className="w-full text-sm text-left text-platform-primary-grey-600">
        <thead className="sticky top-0 text-xs text-platform-primary-grey-800 uppercase bg-platform-secondary-grey-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className="p-2">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              className="bg-white hover:bg-platform-ainc-grey-400 border border-platform-primary-grey-200"
              key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="py-1 px-1.5">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-4" />
    </div>
  );
}
