import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export default function SingleMultiSelectDropdown({
  usersToApprove,
  setUsersToApprove,
  originalOptions,
  keyName,
  filterKey,
  rowKey,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (filterKey) {
      const filteredOptions = originalOptions.filter((option) =>
        usersToApprove.some((user) => user[rowKey] === option[filterKey]),
      );
      setOptions(filteredOptions);
    } else {
      setOptions(originalOptions);
    }
  }, [originalOptions, filterKey, rowKey, usersToApprove]);

  useEffect(() => {
    if (usersToApprove.length === 0) {
      setSelectedOption(null);
    } else {
      const selectedValue = usersToApprove[0][keyName];
      const newSelectedOption = options.find(
        (option) => option.value === selectedValue,
      );
      setSelectedOption(newSelectedOption || null);
    }
  }, [usersToApprove, options, keyName]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    setUsersToApprove(
      usersToApprove.map((user) => ({
        ...user,
        [keyName]: selected ? selected.value : null,
      })),
    );
  };

  return (
    <span className="d-inline-block w-full">
      <Select
        options={options}
        isSearchable
        closeMenuOnSelect
        hideSelectedOptions={false}
        onChange={handleChange}
        className="basic-single-select"
        classNamePrefix="select"
        value={selectedOption}
        // menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999, position: 'fixed' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    </span>
  );
}
